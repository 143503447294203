import { ComponentType, Fragment, Key } from "react";
import {
  RadioGroup,
  RadioGroupProps,
  Radio as NativeRadio,
} from "@headlessui/react";
import joinClassNames from "classnames";
import isObject from "lodash/isObject";

import { ItemType } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props<T extends ItemType, K extends keyof T>
  extends RadioGroupProps<"ul", T extends object ? T[K] : T> {
  items: T[];
  option: ComponentType<{ item: T }>;
  className?: string;
  classNames?: Partial<{ item: string; title: string }>;
  optionKey?: K;
  name?: string;
}

function Radio<T extends ItemType, K extends keyof T>({
  value,
  onChange,
  name,
  items,
  option: Option,
  optionKey,
  className,
  classNames = {},
  ...props
}: Props<T, K>) {
  const valueKey = (optionKey || "value") as keyof T;

  return (
    <RadioGroup
      as="ul"
      className={joinClassNames(classes.list, className)}
      value={value}
      onChange={onChange}
      name={name}
      {...props}
    >
      {items.map(item => {
        const value = isObject(item) ? item[valueKey] : item;

        return (
          <NativeRadio value={value} key={value as Key} as={Fragment}>
            {({ checked }) => (
              <li
                className={joinClassNames(classes.item, classNames.item)}
                aria-selected={checked}
              >
                <Option item={item} />
              </li>
            )}
          </NativeRadio>
        );
      })}
    </RadioGroup>
  );
}

export default Radio;
