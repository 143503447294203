import isObject from "lodash/isObject";

import { GroupedOptions, OptionType, Value } from "./types";

export const getProcessedOption = <T>(option: T): LabelValue<T> | null => {
  if (!option) {
    return null;
  }

  if (getIsLabelValue(option)) {
    return option as LabelValue<T>;
  }

  return {
    label: (option as { id?: string })?.id || String(option),
    value: option as T,
  };
};

export const getIsGrouped = <T>(
  item: OptionType<T>,
): item is GroupedOptions<T> => isObject(item) && "title" in item;

export const getIsLabelValue = (item: unknown): item is LabelValue<Value> =>
  isObject(item) && "label" in item && "value" in item;

export const defaultFilter = <T>(q: string, { label }: LabelValue<T>) =>
  label.toLowerCase().includes(q);

export const isTouchDevice = () =>
  "ontouchstart" in window || navigator.maxTouchPoints > 0;
