import {
  Scrollbar as NativeScrollbar,
  ScrollbarProps,
} from "react-scrollbars-custom";
import merge from "lodash/merge";
import omit from "lodash/omit";
import pick from "lodash/pick";
import isObject from "lodash/isObject";
import joinClassNames from "classnames";

import { getCSSVariables } from "selectors/common";

import classes from "./styles/classes.module.scss";

interface Props extends Omit<ScrollbarProps, "ref"> {
  trackXOffset?: number;
  trackYOffset?: number | Partial<{ top: number; bottom: number }>;
  rightOffset?: number;
  thumbWidth?: number;
  className?: string;
  classNames?: Partial<{
    yTrackWrapper: string;
    yTrack: string;
    thumb: string;
  }>;
}

const Scrollbar = ({
  children,
  trackYProps,
  thumbYProps,
  trackXOffset = 4,
  rightOffset = 0,
  trackYOffset = 16,
  thumbWidth = 4,
  wrapperProps,
  style,
  classNames = {},
  ...restProps
}: Props) => {
  const variables: Record<string, string> = {
    rightOffset: `${rightOffset}px`,
    thumbWidth: `${thumbWidth}px`,
    trackXOffset: `${trackXOffset}px`,
  };
  if (isObject(trackYOffset)) {
    const { top = 16, bottom = 16 } = trackYOffset;

    variables.trackYTopOffset = `${top}px`;
    variables.trackYBottomOffset = `${bottom}px`;
  } else {
    variables.trackYTopOffset =
      variables.trackYBottomOffset = `${trackYOffset}px`;
  }

  return (
    <NativeScrollbar
      {...restProps}
      style={merge(getCSSVariables(variables), style)}
      wrapperProps={merge({ style: { right: 0 } }, wrapperProps)}
      scrollerProps={{
        renderer: ({ elementRef, style, ...props }) => {
          const marginRight = style.marginRight as number;

          return (
            <div
              style={{
                ...style,
                marginRight:
                  style.paddingRight &&
                  style.paddingRight !== Math.abs(marginRight)
                    ? marginRight + 1
                    : marginRight,
              }}
              ref={elementRef}
              {...props}
            />
          );
        },
      }}
      trackYProps={merge(
        {
          renderer: ({ elementRef, className, ...props }) => (
            <div
              className={joinClassNames(
                classes.yTrackWrapper,
                classNames.yTrackWrapper,
              )}
            >
              <div
                className={joinClassNames(
                  classes.yTrack,
                  classNames.yTrack,
                  className,
                )}
                ref={elementRef}
                {...omit(props, "style")}
              />
            </div>
          ),
        },
        trackYProps,
      )}
      thumbYProps={merge(
        {
          renderer: ({ elementRef, style, className, ...props }) => (
            <div
              className={joinClassNames(
                classes.thumb,
                classNames.thumb,
                className,
              )}
              ref={elementRef}
              style={pick(style, ["transform", "height"])}
              {...props}
            />
          ),
        },
        thumbYProps,
      )}
    >
      {children}
    </NativeScrollbar>
  );
};

export default Scrollbar;
