import { ComponentProps, FC, Fragment } from "react";
import { ComboboxOption } from "@headlessui/react";

import Tick24Icon from "icons/tick24.svg?react";

import { RenderOption, Sizes } from "../../duck/types";

import selectClasses from "../../styles/classes.module.scss";
import styles from "./styles/option.module.scss";

interface Props {
  size: Sizes;
  data: LabelValue;
  renderOption: RenderOption;
  component: FC<ComponentProps<typeof ComboboxOption>>;
}

const Option: FC<Props> = ({
  size,
  component: Wrapper,
  data: { label, value, disabled },
  renderOption: OptionComp,
}) => (
  <Wrapper
    value={value}
    key={label}
    disabled={Boolean(disabled)}
    as={Fragment}
    data-size={size}
    className={selectClasses.sizePresets}
  >
    {({ selected, disabled }) => (
      <li className={styles.option} aria-disabled={disabled}>
        <OptionComp
          size={size}
          disabled={disabled}
          selected={selected}
          value={value}
        />
        {selected && <Tick24Icon className={styles.tickIcon} />}
      </li>
    )}
  </Wrapper>
);

export default Option;
