export const SCROLLBAR_PROPS = {
  contentProps: { style: { padding: "16px 0", display: "block" } },
};

const LIST_OFFSET = 8;

export const ANCHOR = {
  gap: LIST_OFFSET,
  padding: LIST_OFFSET,
  to: "bottom",
} as const;
