import { forwardRef, ReactNode } from "react";
import { ScrollbarProps } from "react-scrollbars-custom";

import NativeScrollbar from "components/scrollbar";

import { SCROLLBAR_PROPS } from "../../duck/constants";

interface Props extends Omit<ScrollbarProps, "ref"> {
  children: ReactNode;
  maxHeight?: number | string;
}

const Scrollbar = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => (
    <NativeScrollbar
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      elementRef={ref}
      translateContentSizeYToHolder
      contentProps={SCROLLBAR_PROPS.contentProps}
    >
      {children}
    </NativeScrollbar>
  ),
);

export default Scrollbar;
