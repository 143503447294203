import { useEffect, useRef } from "react";

import { isTouchDevice } from "./selectors";

export const useScroll = <T extends HTMLElement>(
  open: boolean,
  onClose: (trigger: T) => void,
) => {
  const triggerRef = useRef<T>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTouchDevice()) {
      return;
    }

    const handleScroll = (event: Event) => {
      if (optionsRef.current?.contains(event.target as Node)) {
        return;
      }

      onClose(triggerRef.current);
    };

    if (open) {
      document.addEventListener("scroll", handleScroll, true);
    }

    return () => {
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [open]);

  return { optionsRef, triggerRef };
};
